<template>
  <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
    <span class="mr-4">
      <i class="flaticon-chat-1 icon-2x text-muted font-weight-bold"></i>
    </span>
    <div class="d-flex flex-column w-100">
      <span class="d-flex align-items-center text-dark-75 font-weight-bolder font-size-sm"
        >{{ $t('COLUMNNAMES.Besonderheiten') }} <SavedContent :isSaved="isSaved"></SavedContent
      ></span>
      <TinyMCE
        v-model="text"
        @onKeyUp="$emit('onKeyUp', $event, text)"
        api-key="mgg0srn89nwzbyr2g5bl281e4vt12sb5ksishvboxuur4at7"
        :init="{
          menubar: false,
          placeholder: 'Hier Besonderheiten eintragen...',
          inline: true,
          toolbar: false,
          branding: false,
        }"
      />
    </div>
  </div>
</template>

<script>
import TinyMCE from '@tinymce/tinymce-vue';
import SavedContent from '@/components/common/saved-content.vue';

export default {
  components: { TinyMCE, SavedContent },
  props: {
    isSaved: { type: Boolean },
    value: { type: String },
  },
  data() {
    return {
      text: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.text = newValue;
        }
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
:deep(.mce-edit-focus) {
  background: #ffffff;
}
:deep(p) {
  margin-bottom: 0;
}
.mce-content-body {
  max-height: 38px;
  overflow-y: scroll;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  border-radius: 25px;
  background: rgba($color: #707070, $alpha: 0.1);
}
*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 25px;
  border: 5px solid gray;
}
</style>
