var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center flex-lg-fill mr-5 my-1"},[_vm._m(0),_c('div',{staticClass:"d-flex flex-column w-100"},[_c('span',{staticClass:"d-flex align-items-center text-dark-75 font-weight-bolder font-size-sm"},[_vm._v(_vm._s(_vm.$t('COLUMNNAMES.Besonderheiten'))+" "),_c('SavedContent',{attrs:{"isSaved":_vm.isSaved}})],1),_c('TinyMCE',{attrs:{"api-key":"mgg0srn89nwzbyr2g5bl281e4vt12sb5ksishvboxuur4at7","init":{
        menubar: false,
        placeholder: 'Hier Besonderheiten eintragen...',
        inline: true,
        toolbar: false,
        branding: false,
      }},on:{"onKeyUp":function($event){return _vm.$emit('onKeyUp', $event, _vm.text)}},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"mr-4"},[_c('i',{staticClass:"flaticon-chat-1 icon-2x text-muted font-weight-bold"})])
}]

export { render, staticRenderFns }